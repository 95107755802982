import React, { createContext, useEffect, useState } from "react";

const AppContext = createContext();
const AppProvider = ({ children }) => {
  const [periodoInicial, setPeriodoInicial] = useState("");
  const [cargando, setCargando] = useState(true);
  //SET PERIODO INICIAL
  const periodoUsuario = async () => {
    const URL = `${process.env.REACT_APP_WS_DEP}/periododpe`;
    const resp = await fetch(URL);
    const data = await resp.json();
    setPeriodoInicial(data);
    setCargando(false)
    console.log("desde el contex",data)
  };

  

  
  useEffect(() => {
    periodoUsuario();
  }, []);

  return (
    <AppContext.Provider
      value={{
        periodoUsuario,
        setPeriodoInicial,
        periodoInicial,
        cargando
        //  handleChangePagina
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export { AppProvider };

export default AppContext;
