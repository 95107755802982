import React, { useState, useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import useAppContext from "../hooks/useAppContext";
import CsvDownloadButton from "react-json-to-csv";
import { valideKey } from "../helpers/valida";
import { getMaxDate, getMinDate } from "../helpers/validafecha";

const Buscador = () => {
  const [busqueda, setBusqueda] = useState("");
  const [dataBusqueda, setDataBusqueda] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [totalPagina, setTotalPagina] = useState();
  const [archivoRevision, setArchivoRevision] = useState([]);
  const [archivoAceptada, setArchivoAceptada] = useState([]);
  const [archivoAceptadaFecha, setArchivoAceptadaFecha] = useState([]);
  const [alerta, setAlerta] = useState(false);
  const [alertaM, setAlertaM] = useState("");
  const [fechaInicial, setFechaInicial] = useState("");
  const [fechaFinal, setFechaFinal] = useState("");
  const { periodoInicial, cargando } = useAppContext();
  const [botonArchivo, setbotonArchivo] = useState(false);
  let fechaLimite = false;

   // console.log(fechaFinal, "estamos alli");
  let diferenciaEnMilisegundos = new Date(fechaFinal) - new Date(fechaInicial);

  // Convertir la diferencia a días
  var diferenciaEnDias = diferenciaEnMilisegundos / (1000 * 60 * 60 * 24);
  if (diferenciaEnDias > 15) {
    fechaLimite = true;
  }

 
  console.log(diferenciaEnMilisegundos);
  console.log(
    "La diferencia entre las fechas es de",
    diferenciaEnDias,
    "días."
  );

   // console.log("desde busqueda", periodoInicial);
  useEffect(() => {
    /*const getConsultarDatosInicio = async () => {
      const requestBusqueda = {
        campo: null,
        valor: null,
        limit: "20",
        page: pagina,
        periodo: periodoInicial,
      };
      const request = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBusqueda),
      };
      const URL = `${process.env.REACT_APP_WS_DEP}/busqueda`;
      const resp = await fetch(URL, request);
      const data = await resp.json();
      setDataBusqueda(data.docs);
      setTotalPagina(data.totalPages);
    };*/
    if (!cargando) {
      getConsultarDatosInicio();
    }
  }, [pagina, periodoInicial]);
  //}, [pagina, periodoInicial,dataBusqueda]);

  const getConsultarDatosInicio = async () => {
    const requestBusqueda = {
      campo: null,
      valor: null,
      limit: "20",
      page: pagina,
      periodo: periodoInicial,
    };
    const request = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBusqueda),
    };
    const URL = `${process.env.REACT_APP_WS_DEP}/busqueda`;
    const resp = await fetch(URL, request);
    const data = await resp.json();
    setDataBusqueda(data.docs);
    setTotalPagina(data.totalPages);
  };

  const handleChangePagina = (e, valor) => {
    setPagina(valor);
    //  console.log(valor);
  };

  const getConsultarDatos = async (e) => {
    e.preventDefault();

    let rut = "";
    {
      busqueda.length > 0 ? (rut = "rut") : (rut = null);
    }
    const requestBusqueda = {
      campo: rut,
      valor: busqueda,
      limit: "20",
      page: "pagina",
      periodo: periodoInicial,
    };
    const request = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBusqueda),
    };
    const URL = `${process.env.REACT_APP_WS_DEP}/busqueda`;
    const resp = await fetch(URL, request);
    const data = await resp.json();
    setDataBusqueda(data.docs);
    setAlertaM(data.totalDocs);
  };

  const limpiarDatos = async () => {
    setBusqueda("");
     // console.log(busqueda);

    const requestBusqueda = {
      campo: null,
      valor: null,
      limit: "20",
      page: pagina,
      periodo: periodoInicial,
    };
    const request = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBusqueda),
    };
    const URL = `${process.env.REACT_APP_WS_DEP}/busqueda`;
    const resp = await fetch(URL, request);
    const data = await resp.json();
    setDataBusqueda(data.docs);
    setAlertaM(data.totalDocs);
  };

  const Estado = async (rut) => {
    const requestEstado = {
      rut: rut,
      estado: "1",
      periodo: "periodoInicial",
    };
    const request = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestEstado),
    };
    const URL = `${process.env.REACT_APP_WS_DEP}/editarestado`;
    const resp = await fetch(URL, request);
    const data = await resp.json();
    console.log(data);
    setAlerta(true);
    Swal.fire({
      title: "El estado a cambiado con éxito",
      icon: "success",
      timer: "2000",
      confirmButtonText: "Aceptar",
    });
  };

  const Eliminar = async (rut) => {
    /*const requestEstado = {
      rut: rut,
      estado: "5",
      periodo: "periodoInicial",
    };
    const request = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestEstado),
    };
      const URL = `${process.env.REACT_APP_WS_DEP}/editarestado`;
      const resp = await fetch(URL, request);
      const data = await resp.json();
      console.log(data);
      setAlerta(true);
      Swal.fire({
      title: "El estado a cambiado con éxito",
      icon: "success",
      timer: "2000",
      confirmButtonText: "Aceptar",
    });*/
    Swal.fire({
      title:
        "Esta seguro de eliminar el DPE para esta persona?",
      html: ``,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#198754",
      cancelButtonColor: "#dc3545",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Aceptar",
    }).then((result) => {
        if (result.isConfirmed) {

          const requestEstado = {
            rut: rut,
            estado: "0",
            periodo: "periodoInicial",
          };
          const request = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestEstado),
          };          

          const URL = `${process.env.REACT_APP_WS_DEP}/editarestado`;
          const resp = fetch(URL, request);
          console.log(resp); 

          getConsultarDatosInicio();
          //const data = resp.json();  
          //console.log(data);        
        }
      });
  };  



  const getArchivoFecha = async () => {
     // console.log(periodoInicial);

    const URL = `${process.env.REACT_APP_WS_DEP}/consultaceptadas2/${periodoInicial}/${fechaInicial}/${fechaFinal}`;
    const resp = await fetch(URL);
    const data = await resp.json();
    setArchivoAceptadaFecha(data);
    //  console.log(data.length);
    setbotonArchivo(true);
    //  console.log(data, "prueba fecha");
    //  console.log(fechaFinal);
    
  };
 
 
  const getArvhivoRevision = async () => {
    const URL = `${process.env.REACT_APP_WS_DEP}/descargaexcel/${periodoInicial}/2`;
    const resp = await fetch(URL);
    const data = await resp.json();
    setArchivoRevision(data);
  };
  const getArvhivoAceptada = async () => {
    const URL = `${process.env.REACT_APP_WS_DEP}/descargaexcel/${periodoInicial}/3`;
    const resp = await fetch(URL);
    const data = await resp.json();
    setArchivoAceptada(data);
  };
  useEffect(() => {
    if (periodoInicial) {
      getArvhivoRevision();
      getArvhivoAceptada();
    }
  }, [periodoInicial]);

const validafechamayor  = (e) =>{

  setFechaFinal(e.target.value)
  console.log(typeof(diferenciaEnDias))
  console.log("PROBANDO",fechaInicial)
  if(diferenciaEnDias>-1){
    console.log("no funciona fecha")
  }
  else{
    console.log("FUNCIONA")
  }

}
const descargaArchivos= async () => {
  const nombreReporte  = 'propuestasAceptadas';
  const URL = `${process.env.REACT_APP_WS_DEP}/propuestasAceptadas/${periodoInicial}/${fechaInicial}/${fechaFinal}`;
  console.log(URL);
  try {
    const resp = await fetch(URL);
    if (resp.status === 200) {
      const blod = await resp.blob();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blod);
      link.download = `${nombreReporte}`;
      link.click();
    } 
  } catch (error) {
    return error;
  }
};

const descargaArchivoBanco= async () => {
  const nombreReporte  = 'propuestasAceptadas';
  const URL = `${process.env.REACT_APP_WS_DEP}/propuestasAceptadas2/${periodoInicial}/${fechaInicial}/${fechaFinal}`;
  console.log(URL);
  try {
    const resp = await fetch(URL);
    if (resp.status === 200) {
      const blod = await resp.blob();

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blod);
      link.download = `${nombreReporte}`;
      link.click();
    } else{
      console.log("no se pudo descargar el archivo");
      Swal.fire({
        title: "No existen DPE aceptadas para las fechas consultadas",
        icon: "error",
        timer: "4500",
        confirmButtonText: "Aceptar",
      });
      //alert("asd");
    }
  } catch (error) {
    return error;
  }
};
  return (
    <>
      {" "}
      
      <h2 className="text-center mt-4 mb-4 text-primary bg-light pt-5 pb-5">
        Administración DPE
      </h2>
      <div className="container ">
        <div className="row">
          <div className="border-blue  mb-4">
            <h5 className="alert-heading text-primary">
              {" "}
              <i class="fa-solid text-primary fa-circle-info"></i> Para realizar
              la búsqueda ingrese el Rut con o sin el digito verificador
            </h5>
          </div>
          <form className="col-md-6 col-lg-6" onSubmit={getConsultarDatos}>
            <div className="row">
              <div className="col-md-6 col-lg-6 ">
                <input
                  type=""
                  value={busqueda}
                  onChange={(e) => setBusqueda(e.target.value)}
                  className="form-control"
                  onKeyDown={(e) => valideKey(e)}
                  minLength="4"
                  maxLength="10"
                  id=""
                  placeholder="Ejemplo: 12123123-0  12123123"
                ></input>
              </div>
              <div className="col-md-3 col-lg-2">
                <button
                  onClick={getConsultarDatos}
                  disabled={!busqueda && true}
                  className="btn btn-primary mb-3"
                >
                  Buscar
                </button>
              </div>
              <div className="col-md-3 col-lg-2">
                <button
                  onClick={() => limpiarDatos(busqueda)}
                  disabled={!busqueda && true}
                  className="btn btn-primary mb-3"
                >
                  Limpiar
                </button>
              </div>
            </div>
          </form>

          <div className="col-md-6 col-lg-6">
            <a
            //  href="https://d1yq3jg7q057mn.cloudfront.net/archivos/dpe_pptas_aceptadas.xlsx"
                href={`${process.env.REACT_APP_WS_DEP}/reporteExcelAceptadas/${periodoInicial}/3`}
              className="float-end  col-md-4 btn btn-primary  "
            >
              Aceptadas
              <i className=" fa-solid fa-circle-down"></i>
            </a>{" "}
              {/* <CsvDownloadButton
              className="col-md-3 col-lg-3 float-end btn btn-primary m-2 "
              filename="Archivos Solicitudes de Revisión"
              data={dataBusqueda}
            >
              Revisión  <i className=" fa-solid fa-circle-down"></i>
            </CsvDownloadButton> */}
          </div>
        </div>

        <div>
      
    </div>
        <hr className="my-4 mb-5" />
        <div class="border-blue  mb-4">
          <h5 class="alert-heading text-primary">
            {" "}
            <i class="fa-solid text-primary fa-circle-info"></i> Descarga de archivo con rango de fecha
          </h5>
        </div>
        <form className="row ">
          <div className="col-md-8 col-lg-8">
            <label for="fecha">Seleccione rango Fecha: </label>{" "}
            <input
              className="col-md-4 "
              type="date"
              onChange={(e) => setFechaInicial(e.target.value)}
              min={getMinDate()}
              max={getMaxDate()}
            />{" "}
            <input
              className="col-md-4"
              type="date"
              onChange={validafechamayor}
              max={getMaxDate()}
            />
          </div>

     
          
          
          {/* <div className="col-md-3 col-lg-2">
         
          <button
          onClick={descargaArchivos}
              
          type="button"
          className="btn btn-primary mb-3"
          disabled={
            !fechaFinal.length ||
            !fechaInicial.length ||
            (fechaLimite && true) 
          }
          
          >Descargar Archivo Fecha</button>
           
      
          </div> */}


          <div className="d-grid gap-2 d-sm-flex justify-content-sm-end mt-5">
            <button
                onClick={descargaArchivos}
                    
                type="button"
                className="btn btn-primary mb-3"
                disabled={
                  !fechaFinal.length ||
                  !fechaInicial.length ||
                  (fechaLimite && true) 
                }
            
              >Descargar Archivo Fecha</button>
            <button
              onClick={descargaArchivoBanco}
                  
              type="button"
              className="btn btn-primary mb-3"
              disabled={
                !fechaFinal.length ||
                !fechaInicial.length ||
                (fechaLimite && true) 
              }
          
            >Descargar Archivo Banco</button>
          </div>          

         
        
          {/* <div className="col-md-3 col-lg-2">
            <CsvDownloadButton
              disabled={
                !fechaFinal.length ||
                !fechaInicial.length && 
                (fechaLimite && true) 
              }
              className="btn btn-primary mb-3"
              data={archivoAceptadaFecha}
            >
              Archivo Por fecha<i className=" fa-solid fa-circle-down"></i>
            </CsvDownloadButton>
          </div> */}
          <div className="col-md-12 col-lg-12">
            {fechaLimite === true &&  (
              <p className="alert alert-danger">Debe seleccionar un rango de fecha menor a 15 días</p>
            )}  </div> 
             <div className="col-md-12 col-lg-12">
            {botonArchivo && (
              <p className="alert alert-success">Se genero el archivo, ya puede realizar la descarga </p>
            )}  </div> 
            
      
        </form> 
      </div>
      {alertaM === 0 && (
        <p className="alert alert-danger">El run no cuenta con dpe</p>
      )}
      {alertaM !== 0 && (
        <div className="container">
          <hr className="my-4 mb-5" />

          <div className="row border-blue ">
            <h3 className="my-4 mb-5">Listado Usuarios DPE</h3>
            <div className="table table-responsive mt-5">
              <table className="table table-hover">
                <thead className="table-primary">
                  <tr>
                    <th className="text-center">Rut/Run</th>
                    <th className="text-center">
                      Nombre <i class="fa-solid fa-user"></i>
                    </th>
                    <th className="text-center">
                      Estado{" "}
                      <span class="glyphicon glyphicon-download-alt"></span>
                    </th>
                    <th className="text-center">
                      Archivos Revisión <i class="fa-solid fa-download"></i>{" "}
                    </th>
                    <th className="text-center">
                      Eliminar {" "}
                    </th>                    
                    <th className="text-center"></th>
                  </tr>
                </thead>

                <tbody>
                  {dataBusqueda.map((listado) => {
                    return (
                      <tr key={listado._id}>
                        <td className="text-center">
                          {listado.rut}-{listado.dv}
                        </td>
                        <td className="text-left">{listado.nombre}</td>
                        {listado.estado === "0" && (
                          <td className="text-danger text-center">
                            {"Eliminado"}
                          </td>
                        )}
                        {listado.estado === "1" && (
                          <td className="text-success text-center">
                            {"Disponible"}
                          </td>
                        )}
                        {listado.estado === "2" && (
                          <td className="text-warning text-center">
                            {"En Revisión"}
                          </td>
                        )}
                        {listado.estado === "3" && (
                          <td className="text-info text-center">
                            {"Aceptado"}
                          </td>
                        )}
                        {listado.estado === "4" && (
                          <td className="text-info text-center">{"Pagado"}</td>
                        )}
                        {listado.estado === "1" && (
                          <td className="text-info text-center">
                            {"Sin Información"}
                          </td>
                        )}
                        {listado.estado !== "1" && (
                          <td className="text-info text-center">
                            {""}
                          </td>
                        )}

                        {listado.estado === "2" && (
                          <td className="text-center text-primary">
                            <a
                              download
                              href={`${process.env.REACT_APP_WS_DEP}/descargar/${periodoInicial}/${listado.nombreArchivo}`}
                              className="btn text-info"
                            >
                              {listado.nombreArchivo}{" "}
                              <i className="text-primary fa-solid fa-circle-down"></i>
                            </a>{" "}
                          </td>
                        )}
                        {listado.estado === "2" && (
                          <td className="text-center">
                            <button
                              type=""
                              onClick={() => Estado(listado.rut)}
                              className="btn btn-success"
                            >
                              Disponibilizar
                            </button>
                          </td>
                        )}
                        {listado.estado !== "0" && (
                          <td className="text-center">
                            <button className="btn"                             
                              onClick={() => Eliminar(listado.rut)}                              
                            >                              
                              <i className="text-primary fa-solid fa-trash"></i>
                            </button>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div>
                <Stack
                  spacing={2}
                  direction={"row"}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Pagination
                    count={totalPagina}
                    color="primary"
                    onChange={handleChangePagina}
                  />
                </Stack>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Buscador;
