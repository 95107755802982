export const valideKey = (e) => {
       
    if((e.keyCode < '48' || e.keyCode > '57') 
        && e.keyCode !== 189 && e.keyCode !== 173 && e.keyCode !== 8 && e.keyCode !== 75 && e.keyCode !==86 && (e.keyCode < "95" || e.keyCode > "105") ){
  
      e.preventDefault()
    }
    
  };

  export const bloqueoPegarCopiar = (e) => {
    e.preventDefault()
  }