export const getMaxDate = () => {
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate()); // Establece el máximo en 30 días desde hoy
    return maxDate.toISOString().split('T')[0];
  };

  export const getMinDate = () => {
    const minDate = new Date();
    minDate.setDate(minDate.getDate()-15); // Establece el máximo en 30 días desde hoy
    return minDate.toISOString().split('T')[0];
  };
  console.log(getMaxDate())