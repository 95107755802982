import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Buscador from "../components/Buscador";

const Listados = () => {
  return (
    <div>
      <div className="container-fluid">
        <Buscador />
      </div>
    </div>
  );
};

export default Listados;
